import React from 'react';
import { capitalize, find, isArray, isNumber, stubTrue, toString } from 'lodash';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover } from 'material-ui-popup-state';
import { ProjectAdapted, Property } from '@types';
import {
  getPropertyValueById,
  isAddress,
  isCallDisposition,
  isClientStatus,
  isDate,
  isDealValue,
  isDropdown,
  isEmptyValue,
  isFiles,
  isId,
  isNumeric,
  isPerson,
  isPrimaryEmail,
  isPrimaryPhone,
  isProjectValue,
  isReferredBy,
  isRequestStatus,
  isStage,
  isStatus,
  isUid,
  isButtonOrLink
} from '@utils/properties';
import { formatDate, formatMoney, formatNumber } from '@utils';
import { CopyButton } from '@common/ui';
import { Box } from '@material-ui/core';
import { File } from '@generated/types/graphql';
import { FilesList } from '@components/Property/File/FilesList';
import { Status as ProjectStatus } from '@features/ProjectPortfolio/components/Status';
import { Status as RequestStatus } from '@features/RequestPortfolio/components/Status';
import { Status as ClientStatus } from '@features/ClientPortfolio/components/Status';
import { ProjectStageBadge } from '@components/ProjectStages';
import { UserAvatar } from '@kit/components/UserAvatar';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { Actions, LinkButton, LinkValue, TooltipProperty, WrapperStatus } from './styled';
import { renderPropertyIcon } from '../PropertyIcon';

export const getPropertyRightAdornment = (property: Property) => ({ confidence: '%' })[property.mappedName];

const mockObject = {};

type PropertyValueRenderer = React.FC<{ value: any; property: Property }>;

const renderers: {
  typeCheck: ((prop: Property) => boolean)[];
  Renderer: PropertyValueRenderer;
  formatValue?: (props: { value: any; property: Property }) => string | number;
}[] = [
  {
    typeCheck: [isStatus],
    Renderer: ({ value }) =>
      value ? (
        <WrapperStatus>
          <ProjectStatus isActive status={value} />
          <div>{capitalize(value.replace('_', ' '))}</div>
        </WrapperStatus>
      ) : null
  },
  {
    typeCheck: [isRequestStatus],
    Renderer: ({ value }) =>
      value ? (
        <WrapperStatus>
          <RequestStatus status={value} />
          <div>{capitalize(value.replace('_', ' '))}</div>
        </WrapperStatus>
      ) : null
  },
  {
    typeCheck: [isClientStatus],
    Renderer: ({ value }) =>
      value ? (
        <WrapperStatus>
          <ClientStatus status={value} />
          <div>{capitalize(value.replace('_', ' '))}</div>
        </WrapperStatus>
      ) : null
  },
  {
    typeCheck: [isStage],
    formatValue({ value }) {
      return value && typeof value === 'object' ? value.name : '';
    },
    Renderer: ({ value }) => (value ? <ProjectStageBadge stage={value} /> : null)
  },
  {
    typeCheck: [isCallDisposition],
    Renderer: ({ value }) => (value ? capitalize(value).replace('_', ' ') : null)
  },
  {
    typeCheck: [isReferredBy],
    Renderer: ({ value }) => {
      if (!value) {
        return null;
      }

      return (
        <Box display="flex" alignItems="center" gridColumnGap={8}>
          <ContactAvatar contact={value} size={20} />
          {value.name}
        </Box>
      );
    }
  },
  {
    typeCheck: [isDropdown],
    Renderer: ({ value, property }) => (property.multiple ? ((value as string[]) || []).join(', ') : value || null),
    formatValue: ({ value, property }) => (property.multiple ? ((value as string[]) || []).join(', ') : value || null)
  },
  {
    typeCheck: [isDate],
    Renderer: ({ value }) =>
      formatDate(value as string, value instanceof Date && value.allDay ? 'MM/DD/YYYY' : 'MM/DD/YYYY hh:mm A') || '',
    formatValue: ({ value }) =>
      formatDate(value as string, value instanceof Date && value.allDay ? 'MM/DD/YYYY' : 'MM/DD/YYYY hh:mm A') || ''
  },
  {
    typeCheck: [isPerson],
    Renderer: ({ value }) => {
      if (!value) {
        return null;
      }

      return (
        <Box display="flex" alignItems="center" gridColumnGap={8}>
          <UserAvatar user={value} size={20} />
          {value.firstName} {value.lastName}
        </Box>
      );
    }
  },

  {
    typeCheck: [isAddress],
    Renderer: ({ value }) => (isArray(value) ? value[0] : value)
  },
  {
    typeCheck: [isFiles],
    Renderer: ({ value }) => <FilesList files={(value as File[]) || []} />,
    formatValue: ({ value }) => ((value as File[]) || []).map((file) => file.downloadUrl).join(', ')
  },
  {
    typeCheck: [isButtonOrLink],
    Renderer: ({ value, property }) => {
      const handleClick = (e) => {
        e.stopPropagation();
      };

      if (!value) {
        return null;
      }

      if (property.additional?.linkLabel) {
        return (
          <LinkButton onClick={handleClick} href={value} target="_blank" rel="noopener noreferrer">
            {property.iconUrl &&
              renderPropertyIcon({
                property,
                style: { width: 16, height: 16 }
              })}
            <div>{property.additional.linkLabel}</div>
          </LinkButton>
        );
      }

      return (
        <LinkValue onClick={handleClick} href={value} target="_blank" rel="noopener noreferrer">
          {property.iconUrl &&
            renderPropertyIcon({
              property,
              style: { width: 16, height: 16 }
            })}
          <div>{value.replace(/^https?:\/\//, '')}</div>
        </LinkValue>
      );
    }
  },
  {
    typeCheck: [isDealValue, isProjectValue],
    Renderer: ({ value }) => (isNumber(value) ? <>{formatMoney(value)}</> : null),
    formatValue: ({ value }) => (isNumber(value) ? formatMoney(value) : '')
  },
  {
    typeCheck: [isNumeric],
    Renderer: ({ value, property }) => {
      if (isId(property)) {
        return value;
      }

      if (isUid(property)) {
        return `#${value}`;
      }

      return isNumber(value) ? formatNumber(value) : null;
    },
    formatValue: ({ value, property }) => {
      if (isId(property)) {
        return value;
      }

      if (isUid(property)) {
        return `#${value}`;
      }

      return isNumber(value) ? formatNumber(value) : '';
    }
  },
  {
    typeCheck: [isPrimaryPhone],
    Renderer: ({ value }) => <PhoneNumber value={value} />
  },
  {
    typeCheck: [isPrimaryEmail],
    Renderer: ({ value }) => <EmailAddress value={value} />
  },
  {
    typeCheck: [stubTrue],
    Renderer: ({ value }) => <>{toString(value)}</>
  }
];

export const getPropertyValueRenderer = (property: Property) =>
  find(renderers, ({ typeCheck }) => typeCheck.some((fn) => fn(property)))!;

interface PropertyValueProps {
  ValueWrapper?: React.ElementType;
  MainWrapper?: React.ElementType;
  NameWrapper?: React.ElementType;
  project: ProjectAdapted;
  property: Property;
  withTooltip?: boolean;
  noTitleInTooltip?: boolean;
  withName?: boolean;
  withCopy?: boolean;
  hideEmpty?: boolean;
  mainWrapperProps?: object;
}

export const PropertyValue: React.FC<PropertyValueProps> = (props) => {
  const {
    ValueWrapper,
    MainWrapper = React.Fragment,
    NameWrapper = React.Fragment,
    project,
    property,
    withTooltip = false,
    noTitleInTooltip = false,
    withName = false,
    withCopy = false,
    mainWrapperProps,
    hideEmpty = false
  } = props;

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'property-tooltip'
  });
  const value = getPropertyValueById(project, property);

  const { Renderer, formatValue } = getPropertyValueRenderer(property);

  if (hideEmpty && isEmptyValue(value)) {
    return null;
  }

  const Value = (
    <>
      {Renderer({
        value,
        property
      })}
      {getPropertyRightAdornment(property)}
    </>
  );

  const ValueWithWrapper = ValueWrapper ? (
    <ValueWrapper isOverflowAllowed={isReferredBy(property)} disableName={!withName}>
      {Value}
    </ValueWrapper>
  ) : (
    Value
  );

  const ValueWithName = withName ? (
    <>
      <NameWrapper>{property.name}</NameWrapper>
      {ValueWithWrapper}
    </>
  ) : (
    ValueWithWrapper
  );

  const showValueInTooltip = !isPerson(property) && !isFiles(property) && !isDropdown(property);
  const formattedValue =
    formatValue?.({
      value,
      property
    }) || value;

  return (
    <>
      <MainWrapper
        {...mainWrapperProps}
        {...(withTooltip && !isPerson(property) && !isFiles(property) ? bindHover(popupState) : mockObject)}
        value={value}
        formattedValue={formattedValue}
      >
        {ValueWithName}
        {withCopy && (
          <Actions>
            <CopyButton value={formattedValue} size={16} />
          </Actions>
        )}
      </MainWrapper>
      <HoverPopover
        hidden={noTitleInTooltip && !showValueInTooltip}
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <TooltipProperty>
          {noTitleInTooltip ? '' : <p>{property.name}</p>}
          {showValueInTooltip ? <p>{formattedValue}</p> : null}
        </TooltipProperty>
      </HoverPopover>
    </>
  );
};
