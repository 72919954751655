import { Property, PropertyType } from '@types';
import React from 'react';
import { AddressField, DateTimeField, FileField, InputField, RichTextField, SelectField } from '@kit/components/Form';
import { capitalize } from 'lodash';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { getFullName } from '@utils/utils';
import { DropDownItem } from '@common/Selector/UserSelector/DropDownItem';
import { isStatus } from '@utils/properties';
import { Status } from '@features/ProjectPortfolio/components/Status';
import { ProjectStatusOption } from './styled';

interface PropertyFieldProps {
  property: Property;
  control: any;
  onChanged?: () => void;
  name?: string;
  label?: string;
  isAutoFocus?: boolean;
  clearOnUnmount?: boolean;
}

export const PropertyValueField = ({
  label,
  name,
  property,
  control,
  onChanged,
  isAutoFocus,
  clearOnUnmount = false
}: PropertyFieldProps) => {
  const { data: companyUsers } = useAllCompaniesUsers();

  switch (property.type) {
    case PropertyType.Text: {
      if (property.mappedName === 'description') {
        return (
          <RichTextField
            clearOnUnmount={clearOnUnmount}
            label={label}
            name={name}
            control={control}
            onBlur={onChanged}
          />
        );
      }

      if (property.mappedName === 'address') {
        return (
          <AddressField
            clearOnUnmount={clearOnUnmount}
            label={label}
            name={name}
            control={control}
            onBlur={onChanged}
          />
        );
      }

      return (
        <InputField
          clearOnUnmount={clearOnUnmount}
          label={label}
          autoFocus={isAutoFocus}
          onBlur={onChanged}
          name={name}
          control={control}
        />
      );
    }

    case PropertyType.Link:
      return (
        <InputField
          clearOnUnmount={clearOnUnmount}
          label={label}
          autoFocus={isAutoFocus}
          onBlur={onChanged}
          name={name}
          control={control}
          valuePrefix="https://"
        />
      );

    case PropertyType.Numeric:
      return (
        <InputField
          clearOnUnmount={clearOnUnmount}
          label={label}
          type="number"
          autoFocus={isAutoFocus}
          name={name}
          control={control}
          onBlur={onChanged}
        />
      );
    case PropertyType.Dropdown:
      if (isStatus(property)) {
        return (
          <SelectField
            clearOnUnmount={clearOnUnmount}
            label={label}
            onBlur={onChanged}
            isMulti={property.multiple}
            name={name}
            control={control}
            renderOption={(option: any) => (
              <ProjectStatusOption>
                <Status isActive status={option.id} />
                <div>{option.label}</div>
              </ProjectStatusOption>
            )}
            getOptionLabel={(option: any) => option.label}
            getOptionSelected={(option: any, value: any) => option.id === value?.id || option.id === value}
            options={(property.additional?.values || []).map((value) => ({
              label: capitalize(value.replace('_', ' ')),
              id: value
            }))}
          />
        );
      }

      return (
        <SelectField
          clearOnUnmount={clearOnUnmount}
          label={label}
          onBlur={onChanged}
          isMulti={property.multiple}
          name={name}
          control={control}
          options={property.additional?.values || []}
        />
      );
    case PropertyType.Date:
      return (
        <DateTimeField
          clearOnUnmount={clearOnUnmount}
          label={label}
          onClose={onChanged}
          initialIsOpen={isAutoFocus}
          placeholder="Select date"
          name={name}
          control={control}
          isOnlyDate
          isClearable
          withPortal
        />
      );
    case PropertyType.Person:
      return (
        <SelectField
          clearOnUnmount={clearOnUnmount}
          label={label}
          name={name}
          control={control}
          options={companyUsers}
          getOptionLabel={getFullName}
          getOptionValue={(option: any) => option.id}
          onBlur={onChanged}
          noOptionsText="User not found"
          renderOption={(option: any) => (
            <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
          )}
        />
      );
    case PropertyType.File:
      return (
        <FileField clearOnUnmount={clearOnUnmount} label={label} name={name} control={control} onChanged={onChanged} />
      );
    default:
      throw new Error(`Unrecognized property type ${property.type}`);
  }
};
